import {
  Actionables,
  CheckEmailExistsResponse,
  UserSignUpResponse,
  SubscribeToMarketingEmailsResponse,
  User,
  ActivateUserProfileResponse,
  ResendActivationCodeResponse,
  CheckCountryCodeResponse,
  CheckPreferredCurrencyResponse,
  UpdateGeorgraphicalRegionParams,
  CookieConsentResponse,
  LeadTouchLevel,
  UserSiteInteraction,
  UpdatePreferredLocalizationResponse,
  UpdatePreferredLocalizationParams,
  UpdatePreferredGeorgraphicalRegionResponse,
  Session,
  OtpResponse,
  OtpDeliveryResponse,
  SessionParams,
} from './Types'
import httpClient from '../httpClient'
import { OtpDeliveryFunctionArgs } from '../../components/common/providers/SignInProvider'
import I18n from '../../helpers/I18n'


const UserService = {
  updateProfile: function (userProfileData, callBack) {
    $.ajax({
      type: 'POST',
      url: '/api/user/profile',
      beforeSend: $.rails.CSRFProtection,
      data: { payload: JSON.stringify(userProfileData) },
      success: res => {
        callBack(res, null)
      },
      error: res => {
        callBack(null, res.responseJSON)
      },
    })
  },

  deleteAccount: function (userId, password, callBack, newOwnerId = undefined) {
    $.ajax({
      type: 'POST',
      url: `/users/${userId}/delete_account`,
      beforeSend: $.rails.CSRFProtection,
      data: {
        password,
        new_owner_id: newOwnerId,
      },
      success: res => {
        callBack({ message: res.message }, null)
      },
      error: res => {
        callBack(null, { message: res.responseJSON?.message })
      },
    })
  },

  reportActivity: function (operation, user_id, operation_data, cb) {
    $.ajax({
      type: 'POST',
      url: '/report_activity',
      beforeSend: $.rails.CSRFProtection,
      data: {
        operation: operation,
        user_id: user_id,
        operation_data: operation_data,
      },
      success: function (res) {
        if (undefined != cb && 'function' === typeof cb) {
          cb(res)
        }
      },
    })
  },

  trackTouchLevel: function (touch: LeadTouchLevel) {
    return httpClient.post('/public_api/users/lead_touch_quality', {
      lead_touch_level: touch,
    })
  },

  activateUserProfile: async function (
    activationCode: string,
    firstName: string,
    lastName: string,
    phone: string,
    password: string,
    role: string,
    profileId: number,
    invitationCode: string,
    timezone: string,
    consent?: boolean,
    platformReferralToken?: string
  ): Promise<ActivateUserProfileResponse> {
    try {
      const response = await httpClient.post(`/users/${activationCode}/activate_user_profile`, {
        first_name: firstName,
        last_name: lastName,
        role: role,
        password: password,
        phone: phone,
        user_id: profileId,
        invitation_code: invitationCode,
        consent: consent,
        timezone,
        platform_referral_token: platformReferralToken,
      })

      if (response.data.recordGA) {
        const enhancedConversionData = {
          email: response.data.user_email,
          phone: phone,
        }
        GoogleAnalyticsHelper.invokeGAActivationCallbacks(enhancedConversionData)

        // LinkedIn Conversion Tracking
        if (window.lintrk) {
          window.lintrk('track', { conversion_id: 4044529 })
        }
      }

      UserService.reportActivity(
        'onboarding',
        response.data.user_id,
        {
          step: 'activated_through_onboarding',
        },
        () => undefined
      )

      GoogleAnalyticsHelper.VentionGAEvent(
        'activated_through_onboarding',
        'click',
        'onboarding',
        response.data.user_id
      )

      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  checkEmailExists: async function (inviteEmail: string): Promise<CheckEmailExistsResponse> {
    try {
      const response = await httpClient.post('/user/check_email', {
        email: inviteEmail,
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  resendActivationCode: async function (): Promise<ResendActivationCodeResponse> {
    try {
      const response = await httpClient.get('/resend_activation_code')
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  userSignUp: async function (
    email: string,
    consent: boolean,
    role: string,
    phone: string,
    captcha: string,
    referral_code?: number | null,
    referral_source?: string | null,
    signupFlow?: 'regular' | 'button_on_home_page'
  ): Promise<UserSignUpResponse> {
    try {
      const response = await httpClient.post('/users/', {
        user: { email },
        consent: consent,
        role: role,
        phone: phone,
        captcha: captcha,
        referral_code: referral_code,
        referral_source: referral_source,
        signup_flow: signupFlow,
        ga_client_id: window.ga_client_id,
      })
      const enhancedConversionData = { email, phone }

      GoogleAnalyticsHelper.invokeGAUserCreationCallback(enhancedConversionData)
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  userSignIn: (
    { email, password, rememberMe, otpAttempt, signInFirstStageToken },
    timezone
  ): Promise<Session | OtpResponse> => {
    const params: SessionParams = {
      email,
      remember_me: rememberMe,
      otp_attempt: otpAttempt,
      sign_in_first_stage_token: signInFirstStageToken,
      timezone,
      return_to: new URLSearchParams(window.location.search).get('return_to'),
    }

    if (password) {
      params.password = password
    }

    return httpClient
      .post<Session | OtpResponse>('/public_api/sessions', params)
      .then(response => {
        if ('authenticity_token' in response.data) {
          const authenticityToken = response.data.authenticity_token
          if (authenticityToken) {
            document
              .querySelector('meta[name="csrf-token"]')
              ?.setAttribute('content', authenticityToken)
          }
        }
        return response.data
      })
      .catch(error => ({
        error: {
          message:
            error?.response?.data?.message || 'Could not sign in due to incorrect credentials.',
          type: error?.response?.data?.type,
        },
      }))
  },

  deliverOtp: ({
    email,
    signInFirstStageToken,
    otpMethod,
  }: OtpDeliveryFunctionArgs): Promise<OtpDeliveryResponse> =>
    httpClient
      .post<OtpDeliveryResponse>('/public_api/users/otp_deliveries', {
        email,
        sign_in_first_stage_token: signInFirstStageToken,
        otp_method: otpMethod,
      })
      .then(response => response.data)
      .catch(error => {
        return {
          error: {
            message:
              error?.response?.data?.message || I18n.t('frontend.services.user.deliver_otp.error'),
          },
        }
      }),

  /**
   * Used by assembler
   */
  currentUser: async function (): Promise<User> {
    try {
      const response = await httpClient.get('/fetch_current_user')
      return response.data.currentUser
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  fetchUser: async function (): Promise<User> {
    try {
      const response = await httpClient.get('/public_api/users/fetch_user')
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  fetchOnboardingUrl: async function (): Promise<string> {
    try {
      const response = await httpClient.get('/fetch_onboarding_url')
      return response.data.onboarding_tutorial_url
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  subscribeToMarketingEmails: async function (
    email: string
  ): Promise<SubscribeToMarketingEmailsResponse> {
    try {
      const response = await httpClient.post('/subscribe', {
        email: email,
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  checkCountryCode: async function (): Promise<CheckCountryCodeResponse> {
    try {
      const response = await httpClient.get('/check_country_code')
      return response.data.country_code
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  checkPreferredCurrency: async function (): Promise<CheckPreferredCurrencyResponse> {
    try {
      const response = await httpClient.get('/public_api/users/currency')
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  checkCookieConsent: async function (): Promise<CookieConsentResponse> {
    try {
      const response = await httpClient.get('/public_api/users/cookie_consent?format=json')
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  updateCookieConsent: async function (): Promise<CookieConsentResponse> {
    try {
      const response = await httpClient.post(
        '/public_api/users/cookie_consent/update',
        {},
        {
          headers: { Accept: 'application/json' },
        }
      )
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  updateGeorgraphicalRegion: async function (
    url: string,
    params: UpdateGeorgraphicalRegionParams
  ): Promise<UpdatePreferredGeorgraphicalRegionResponse> {
    try {
      const response = await httpClient.patch(url, { ...params })
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  updatePreferredLocalization: async function (
    url: string,
    params: UpdatePreferredLocalizationParams = {},
    method: 'patch' | 'post' = 'patch'
  ): Promise<UpdatePreferredLocalizationResponse> {
    const config = { url, params, method }

    try {
      const response = await httpClient.request(config)
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  requestChangePassword: function (userId, password, callBack) {
    $.ajax({
      type: 'POST',
      url: `/users/${userId}/request_change_password`,
      beforeSend: $.rails.CSRFProtection,
      data: { password },
      success: res => {
        callBack(res)
      },
      error: res => {
        callBack(null, res)
      },
    })
  },

  changePassword: function (userId, newPassword, newPasswordConfirmation, callBack) {
    $.ajax({
      type: 'POST',
      url: `/users/'${userId}'/change_password`,
      data: {
        password: newPassword,
        password_confirmation: newPasswordConfirmation,
      },
      success: res => {
        callBack({ message: res.message })
      },
      error: res => {
        callBack(null, {
          message: res.responseJSON ? res.responseJSON.message : 'An error has occurred',
        })
      },
    })
  },

  fetchActionables: function (callBack: (res: Actionables | null) => void) {
    httpClient
      .get('/dashboard/next_step_module')
      .then(res => callBack(res.data as Actionables))
      .catch(() => callBack(null))
  },

  saveSiteInteraction: async function (interaction: UserSiteInteraction) {
    try {
      const response = await httpClient.post('/public_api/users/site_interaction', interaction)
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },
}

export default UserService
